
















import Vue from 'vue'
import Component from 'vue-class-component'
import SygniToggleSwitch from "@/components/inputs/SygniToggleSwitch.vue";
import SygniSelect from "@/components/inputs/SygniSelect.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniRectButton from "@/components/buttons/SygniRectButton.vue";
import SygniRadio from "@/components/inputs/SygniRadio.vue";
import SygniTextArea from "@/components/inputs/SygniTextArea.vue";
import SygniCheckbox from "@/components/inputs/SygniCheckbox.vue";
import SygniInput from "@/components/inputs/SygniInput.vue";
import ExpandCollapse from "@/components/animations/ExpandCollapse.vue";
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";
import SygniLoader from '@/components/layout/SygniLoader.vue';
import SygniInputError from "@/components/inputs/SygniInputError.vue";
import EditorComponent from '@/modules/genprox/components/wysiwyg/EditorComponent.vue';
import AutentiForm from '../components/AutentiForm.vue';
import SygniModal from '@/components/layout/SygniModal.vue';
import ParticipantsTable from '@/modules/genprox/modules/fund/modules/capital-rise/modules/templates/components/ParticipantsTable.vue';
import { mapGetters } from 'vuex'
import { GlobalSelects } from '@/modules/shared/dictionary-store/types';
import { NavigationGuardNext, Route } from 'vue-router';

Component.registerHooks(['beforeRouteLeave'])
@Component({
  components: {
    AutentiForm,
    EditorComponent,
    SygniInputError,
    SygniToggleSwitch,
    SygniRadio,
    ExpandCollapse,
    SygniModal,
    SygniRoundedButton, SygniRectButton, SygniTextArea, SygniCheckbox, SygniSelect, SygniInput, SygniContainerTitle, SygniLoader, ParticipantsTable },
  computed: {
    ...mapGetters('dictionary', {
      globalSelects: 'globalSelects',
    }),
  }
})
export default class AutentiFormModule extends Vue {
  globalSelects!: GlobalSelects;
  hasChanges: boolean = false;
  showLeavingModal: boolean = false;
  isLeavingModalLoading: boolean = false;
  nextRoute: NavigationGuardNext<Vue> = null;

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData'];
  }

  get hasAutenti() {
    return this.activeUserData?.autenti?.enabled
  }

  get isMarketplaceAccountant() {
    return this.activeUserData?.role?.marketplace === 'accountant' ? true : false;
  }

  changeRoute() {
    if (this.nextRoute) this.nextRoute();
  }

  async confirmSavingTemplate() {
    this.saveTemplate(this.nextRoute);
  }

  async saveTemplate(nextRoute?: NavigationGuardNext<Vue>) {
    this.isLeavingModalLoading = true

    try {
      await (this.$refs?.autentiForm as AutentiForm)?.handleSubmitPromise(false, null)
      if (!(this.$refs?.autentiForm as AutentiForm)?.$v?.$error) {
        if (nextRoute) nextRoute();
      } else {
        this.showLeavingModal = false
      }
    } catch (e) {
      if (this.$refs?.autentiForm) {
        (this.$refs.autentiForm as AutentiForm).isLoading = false;
      }
      
      const errorMessage = this.$options.filters.errorHandler(e);
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: this.$t(errorMessage).toString()
      })
    }

    this.isLeavingModalLoading = false;
  }

  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
    if (this.hasChanges) {
      this.showLeavingModal = true;
      this.nextRoute = next;
    } else {
      next();
    }
  }
}

